import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 250 250">
    <title>Logo</title>
    <g transform="translate(15, 10)">
      <path
        d="M550 2041 c-12 -23 -14 -1559 -2 -1578 6 -9 65 -12 232 -10 l224 2
        -3 191 c-3 220 -2 224 79 297 73 66 144 90 245 85 88 -5 139 -25 213 -85 l42
        -34 0 -153 c0 -84 3 -187 6 -229 l7 -77 228 0 229 0 0 276 0 276 -40 67 c-110
        190 -251 324 -400 383 -174 69 -321 64 -521 -16 -36 -14 -71 -26 -77 -26 -9 0
        -12 65 -12 249 l0 249 -31 11 c-18 6 -42 11 -55 11 -40 0 -207 60 -267 95 -67
        40 -82 43 -97 16z"
        fill="black"
      />
    </g>
  </svg>
);

export default IconLogo;
